<template>
    <b-modal id="modal-help" size="lg">
        <template v-slot:modal-title>{{$t('modal_help.title')}}</template>
        <h4><b-icon icon="dot"></b-icon>Android</h4>
        {{$t('modal_help.android')}}
        <hr>
        <h4><b-icon icon="dot"></b-icon>iOS</h4>
        <div class="mb-2">{{$t('modal_help.ios')}}</div>
        <div>{{$t('modal_help.ios_2')}}</div>

        <hr>
        <h5><b-icon icon="dot"></b-icon>{{$t('modal_help.other_use')}}</h5>
        {{$t('modal_help.other')}}
        <template v-slot:modal-footer>
            <div class="w-100 d-flex justify-content-center">
                <b-button variant="warning" class="d-flex" @click="hideModal">
                        <span>
                            {{$t('live.close')}}
                        </span>
                    <b-icon icon="x" font-scale="1.5" class="ml-3 align-self-center"/>
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
    //import {ConfJson} from "@/main";


    export default {
        name: "HelpModal",
        props: {},
        data: function () {
            return {
            }
        },
        components: {

        },
        created: function () {

        },
        computed: {

        },
        methods: {
            hideModal() {
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-help')
                })
            }
        },
    }
</script>

<style scoped>
    .border-dash-style {
        flex-wrap: wrap;
        padding: 5px;
        border: 2px dashed rgba(0, 0, 0, 0.5);
    }

    .border-dash-style > button {
        width: 75px;
        word-wrap: break-word;
        min-height: 40px;
        margin: 2px;
    }
</style>