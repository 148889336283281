import Vue from 'vue'
import Vuex from 'vuex'

import key from './modules/key'


Vue.use(Vuex)

const store = new Vuex.Store(   {
    modules: {
        key,
    }
})


export default store

