<template>
    <div class="about">
        <modal-help/>
        <!--        <video id="fullscreen-remote-video" :poster="img_video_loading_1" class="fullscreen" src="" playsinline autoplay
                       muted
                       v-on:click="openFullscreen('fullscreen_remote_video')"
                       v-bind:class="fullscreen_remote ? '' : 'd-none'"/>
                <video id="fullscreen-local-video" :poster="img_video_loading_2" class="fullscreen" src="" playsinline autoplay
                       muted
                       v-on:click="openFullscreen('fullscreen_local_video')"
                       v-bind:class="fullscreen_local ? '' : 'd-none'"/>-->
        <Loading v-if="loading"/>

        <perfect-scrollbar v-else class="vh-100">
            <div class="p-1 d-flex justify-content-between">

                <div style="z-index: 1032" class="d-flex">
                    <img v-bind:src="logoPath" alt="" class="logo">
                    <b-button v-if="debug" variant="secondary" class="ml-2  align-self-center d-flex" v-b-modal.modal-sdp>
                        SDP
                        <modal-sdp/>
                    </b-button>
                </div>
                <div class="align-self-center">
                    <b-icon icon="info-circle" font-scale="1.7" class="mr-2" style="cursor: pointer" v-b-modal.modal-help/>
                    {{version}}
                  <span id="bandwidth"  v-if="debug" class="p-1 ml-2 border rounded"></span>
                </div>
                <b-badge id="status" class="align-self-center" v-bind:variant="rtc.status_color" data-toggle="tooltip"
                         data-placement="bottom" v-bind:title="$t('live.status_0')">{{$t('live.status')}} <span
                        class="second-status">: {{rtc.status}}</span></b-badge>
                <b-tooltip target="status" noninteractive placement="bottom">{{rtc.status}}</b-tooltip>
                <!--                <div  class="align-self-center badge badge-primary"
                                     data-toggle="tooltip" data-placement="bottom" v-bind:title="$t('live.status_0')">{{$t('live.status')}}
                                </div>-->
                <b-button variant="secondary" class=" align-self-center d-flex" v-b-modal.modal-config>
                    <b-icon icon="gear" font-scale="1.5" class="align-self-center"/>
                </b-button>
                <modal-config title="Line Modal"/>
            </div>

            <div class="d-flex p-1 flex-column">
                <div class="position-relative">
                    <Errors/>
                </div>
                <div class="videos-container">
                    <div class="" style="">
                        <video id="remote-video" class="" :poster="img_video_loading_1"
                               v-bind:class="fullscreen_remote ? 'fullscreen' : ''"
                               v-on:click="openFullscreen('fullscreen_remote_video')" autoplay playsinline>
                        </video>
                        <audio id="remote-audio" src="" class="d-none" autoplay/>
                        <!--<b-button variant="outline-secondary" class="position-absolute " style="bottom: 10px; right: 10px"><b-icon  icon="fullscreen" font-scale="2" /></b-button>-->
                    </div>
                    <div class="d" style="">
                        <video id="local-video" class="w-100" :poster="img_video_loading_2"
                               v-bind:class="fullscreen_local ? 'fullscreen' : ''"
                               v-on:click="openFullscreen('fullscreen_local_video')" autoplay playsinline muted/>
                        <audio id="local-audio" src="" class="d-none" autoplay muted/>
                    </div>
                </div>

                <div id="footer"  style="z-index: 1032"  v-bind:class="fullscreen ? 'fullscreen-footer' : ''">
                    <div class="bottom-container d-flex align-self-center justify-content-center ">
                        <div v-if="!rtc.in_progress" class="bottom-part1 d-flex justify-content-around  ">
                            <b-button v-if="!rtc.on_trying" variant="success" class="d-flex align-self-center"
                                      v-on:click="connect">
                        <span class="mr-1">
                            {{$t('live.start')}}
                        </span>
                                <b-icon icon="telephone" font-scale="1.5" class="ml-3 align-self-center"/>
                            </b-button>
                            <b-button v-else variant="info" class="d-flex align-self-center" disabled>
                        <span>
                            {{$t('live.on_trying')}}
                        </span>
                                <b-spinner small class="ml-3 align-self-center"/>
                            </b-button>
                        </div>
                        <div v-else-if="rtc.in_progress" class="bottom-part1 d-flex flex-wrap  ">
                            <b-button variant="danger" class="d-flex align-self-center m-1 mr-auto" @click="stop">
                                <span class="mr-2 txt-end-call">
                                    {{$t('live.stop')}}
                                </span>
                                <b-icon icon="telephone" font-scale="1.6"
                                        class="align-self-center rotate"/>
                            </b-button>
                            <div class="d-flex m-1  flex-wrap  justify-content-around ">
                                <b-button v-bind:variant="rtc.audio_muted ? 'success' : 'danger'"
                                          class="d-flex m-1 align-self-center" @click="muteAudio">
                                    <b-icon v-if="!rtc.audio_muted" icon="mic-mute" font-scale="1.7"
                                            class="ml-1 align-self-center"/>
                                    <b-icon v-else icon="mic" font-scale="1.7" class="ml-1 align-self-center"/>
                                </b-button>
                                <b-button v-bind:variant="rtc.video_muted ? 'success' : 'danger'"
                                          class="d-flex m-1 align-self-center" @click="muteVideo">
                                    <b-icon v-if="!rtc.video_muted" icon="camera-video-off" font-scale="1.7"
                                            class="ml-1 align-self-center"/>
                                    <b-icon v-else icon="camera-video" font-scale="1.7" class="ml-1 align-self-center"/>
                                </b-button>
                                <b-button variant="info" v-if="rtc.second_camera"
                                          class="d-flex m-1 align-self-center position-relative"
                                          @click="changeToOldVideoInput">
                                    <svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img"
                                         aria-label="camera" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                         class="bi-camera ml-1 align-self-center b-icon bi" style="font-size: 170%;">
                                        <g>
                                            <path fill-rule="evenodd"
                                                  d="M15 12V6a1 1 0 0 0-1-1h-1.172a3 3 0 0 1-2.12-.879l-.83-.828A1 1 0 0 0 9.173 3H6.828a1 1 0 0 0-.707.293l-.828.828A3 3 0 0 1 3.172 5H2a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z"></path>

                                            <path d="M3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"></path>
                                        </g>
                                    </svg>
                                    <svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img"
                                         aria-label="arrow" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                         class="bi-arrow ml-1 align-self-center b-icon bi position-absolute"
                                         style="font-size: 115%;left: 17px;top: 12px;">
                                        <g style="font-size: 100%;">
                                            <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"></path>
                                            <path fill-rule="evenodd"
                                                  d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"></path>
                                        </g>
                                    </svg>
                                </b-button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </div>
</template>

<script>

    import {ConfJson, version} from "@/main";
    import Loading from "@/components/Loading"
    import Errors from "@/components/Errors"
    import ModalConfig from "@/components/ModalConfig";
    import ModalHelp from "@/components/ModalHelp";
    import ModalSdp from "@/components/ModalSdp";
    //import vueFullscreen from 'vue-fullscreen'
    /*    import fullscreen from 'vue-fullscreen'
        import Vue from 'vue'
        Vue.use(fullscreen)*/
    import router from "@/router";

    export default {

        name: 'Home',
        metaInfo: {
            title: 'Home',
            titleTemplate: '%s - Ines-RTC',
            htmlAttrs: {
                lang: localStorage.getItem('locale') || 'en',
                amp: true
            }
        },
        created: function () {
            if (this.rtc.in_progress) {

                this.$store.commit('key/closeConnectionWebrtc')
            }
            let MyComponent = this;

            let myInterval = setInterval(function () {
                if (ConfJson != null) {

                    let old_lang = localStorage.getItem('locale')
                    if (old_lang === undefined || old_lang === null || old_lang === "null" || old_lang.length === 0) {
                        MyComponent.$i18n.locale = ConfJson.default_language
                        localStorage.setItem('locale', ConfJson.default_language)
                    }
                    let oldVideoInput = localStorage.getItem('old_video_input')
                    if (oldVideoInput === undefined || oldVideoInput === null || oldVideoInput === "null" || oldVideoInput.length === 0) {
                        MyComponent.second_camera = false
                    }
                    MyComponent.logoPath = ConfJson.logo_path;
                    MyComponent.debug = ConfJson.debug;
                    MyComponent.img_video_loading_1 = ConfJson.img_video_loading_1;
                    MyComponent.img_video_loading_2 = ConfJson.img_video_loading_2;

                    MyComponent.$store.commit('key/setAutoConnect', ConfJson.auto_connect)
                    MyComponent.$store.commit('key/get200')
                    MyComponent.$store.commit('key/getBandwidth')
                    MyComponent.loading = false;
                    clearInterval(myInterval);
                }
            }, 100);
        },
        components: {
            Loading,
            Errors,
            ModalConfig,
            ModalHelp,
            ModalSdp
        },
        data: function () {
            return {
                version: version,
                loading: true,
                logoPath: "",
                debug: false,
                img_video_loading_1: "",
                img_video_loading_2: "",
                fullscreen: false,
                fullscreen_remote: false,
                fullscreen_local: false,
                accountName: localStorage.getItem("accountName"),
                selected: 'audio',
                //second_camera: true,
                connexion_type: [{text: 'Audio', value: 'audio'},
                    {text: 'Audio & Video', value: 'video'},]
            }
        },
        computed: {
            rtc() {
                return this.$store.state.key.rtc_connection
            },
        },
        methods: {
            openFullscreen(id) {
                switch (id) {
                    case "fullscreen_remote_video":
                        this.fullscreen = !this.fullscreen_remote
                        this.fullscreen_remote = !this.fullscreen_remote
                        break;
                    case "fullscreen_local_video":
                        this.fullscreen = !this.fullscreen_local
                        this.fullscreen_local = !this.fullscreen_local
                        break;
                    default:
                        return
                }
                //console.log(id)

                /*
                                if (this.$fullscreen.supportFullScreen()) {
                                    this.$fullscreen.toggle(this.$el.querySelector('#' + id), {
                                        wrap: false,
                                        //callback: this.fullscreenChange
                                    })
                                } else {
                                    let elem = document.getElementById(id);
                                    if (elem.webkitEnterFullScreen) {
                                        // Toggle fullscreen in Safari for iPad
                                        elem.webkitEnterFullScreen();
                                    } else if (elem.requestFullscreen) {
                                        elem.requestFullscreen();
                                    } else if (elem.mozRequestFullScreen) { //Firefox
                                        elem.mozRequestFullScreen();
                                    } else if (elem.webkitRequestFullscreen) { // Chrome, Safari & Opera
                                        elem.webkitRequestFullscreen();
                                    } else if (elem.msRequestFullscreen) { // IE/Edge
                                        elem.msRequestFullscreen();
                                    }
                                }
                */

                /**/
            },
            connect() {
                this.$store.commit('key/connect')
            },
            muteAudio() {
                this.$store.commit('key/muteAudio')
            },
            muteVideo() {
                this.$store.commit('key/muteVideo')
            },
            stop() {
                localStorage.removeItem('s');
                router.go('/');
            },
            changeToOldVideoInput() {
                this.$store.commit('key/changeVideoInputState')
                this.$store.commit('key/changeVideoInput')
            },
        }
    }
</script>

<style>
    img {
        max-width: 100px;
        max-height: 100px;
    }

    #remote-video {
        width: 100%;
        height: calc(45vh);
    }


    /*noinspection ALL*/
    #local-video {
        /*NO INSPECTION FOR THE FILL*/
        /*max-height: 200px;*/
        height: calc(45vh - 180px);
        width: 100%;
        /*height: -moz-available;
        height: -webkit-fill-available;
        height: fill-available;*/
    }
    .videos-container {

        width: 100%;
    }
    .videos-container > div {

        width: 100%;
    }

    .rotate {
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -ms-transform: rotate(135deg);
        -o-transform: rotate(135deg);
    }


    .fullscreen {
        top: 0;
        left: 0;
        background-color: white !important;
        position: absolute !important;
        height: 100vh !important;
        width: 100vw !important;
        z-index: 1031 !important;
    }
    .fullscreen-footer {
      width: 100%;
      position: absolute;
      bottom: 110px;
    }


    @media screen and (max-width: 900px) and (max-height: 500px) {
        img {
            max-width: 50px;
            max-height: 50px;
        }

        #remote-video {
            height: 50vh;
        }
        .videos-container {
            display:flex;
            width: 100%;
        }
        .videos-container > div {
            padding: 0.25rem !important;
            width: 50%;
        }
        #local-video {
            height: 50vh;
            /*height: -moz-available;
            height: -webkit-fill-available;
            height: fill-available;*/
        }

        .second-status {
            display: none;
        }

        /*        #footer {
                    height: calc(50vh - 160px);
                }*/
    }

    @media screen and (max-width: 500px) {
        .second-status {
            display: none;
        }
        img {
            max-width: 50px;
            max-height: 50px;
        }

        #local-video {
            height: calc(40vh - 100px);
        }

        #remote-video {
            height: 40vh;
        }

        /*        #footer {
                    height: calc(50vh - 160px);
                }*/
        .txt-end-call {
            display: none;
        }
    }

    /*    @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
            html {
                transform: rotate(-90deg);
                transform-origin: left top;
                width: 100vh;
                overflow-x: hidden;
                position: absolute;
                top: 100%;
                left: 0;
            }
        }*/
</style>
