<template>
  <div class="position-absolute mt-2 error-container" style="">
    <b-alert v-if="error.isWrongBrowser" variant="danger" show>
      {{$t('error.isWrongBrowser')}}
    </b-alert>
    <b-alert v-if="!error.isWebsiteHasMicrophonePermissions || !error.isWebsiteHasWebcamPermissions" variant="danger" show>
      <div v-if="!error.isWebsiteHasMicrophonePermissions && !error.isWebsiteHasWebcamPermissions && rtc.connection_type === 'video'">
        {{$t('error.error_1_2')}}
      </div>
      <div v-else-if="!error.isWebsiteHasMicrophonePermissions">
        {{$t('error.isWebsiteHasMicrophonePermissions')}}
      </div>
      <div v-else-if="!error.isWebsiteHasWebcamPermissions && rtc.connection_type === 'video'">
        {{$t('error.isWebsiteHasWebcamPermissions')}}
      </div>
    </b-alert>
    <b-alert v-if="!error.isWebRTCSupported" variant="danger" show>
      {{$t('error.isWebRTCSupported')}}
    </b-alert>
    <b-alert v-if="!error.hasWebcam" variant="danger" show>
      {{$t('error.hasWebcam')}}
    </b-alert>
    <b-alert v-if="!error.hasMicrophone" variant="danger" show>
      {{$t('error.hasMicrophone')}}
    </b-alert>
    <b-alert v-if="!error.hasSpeakers" variant="danger" show>
      {{$t('error.hasSpeakers')}}
    </b-alert>
  </div>
</template>

<script>

  export default {
    name: "Errors",
    props: {},
    data: function () {
      return {
        connexion_type_values:[
        ],
      }
    },
    components: {
    },
    created: function () {

    },
    computed: {
      rtc() {
        return this.$store.state.key.rtc_connection
      },
      error() {
        return this.$store.state.key.error
      },
    },
    methods: {
    },

  }
</script>

<style>
  .error-container {

    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    width: 600px;
    text-align: center;
  }
  @media screen and (max-width: 800px) {
    .error-container {
      width: 100%;
    }
  }
</style>