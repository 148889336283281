<template>
    <b-modal id="modal-sdp" size="xl">
        <template v-slot:modal-title>SDP</template>
        <div class="d-flex">
            <b-card class="w-50"
                    header="My SDP"
            >
                <b-card-text style="white-space: pre-line">{{sdp.MySdp}}</b-card-text>

            </b-card>
            <b-card class="w-50"
                    header="SDP FROM MANAGER"
            >
                <b-card-text style="white-space: pre-line">{{sdp.SdpManager}}</b-card-text>

            </b-card>
        </div>
        <template v-slot:modal-footer>
            <div class="w-100 d-flex justify-content-center">
                <b-button variant="warning" class="d-flex" @click="hideModal">
                        <span>
                            {{$t('live.close')}}
                        </span>
                    <b-icon icon="x" font-scale="1.5" class="ml-3 align-self-center"/>
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
    //import {ConfJson} from "@/main";


    export default {
        name: "SdpModal",
        props: {},
        data: function () {
            return {
            }
        },
        components: {

        },
        created: function () {

        },
        computed: {
            sdp() {
                return this.$store.state.key.sdp
            },
        },
        methods: {
            hideModal() {
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-sdp')
                })
            }
        },
    }
</script>

<style scoped>
    .border-dash-style {
        flex-wrap: wrap;
        padding: 5px;
        border: 2px dashed rgba(0, 0, 0, 0.5);
    }

    .border-dash-style > button {
        width: 75px;
        word-wrap: break-word;
        min-height: 40px;
        margin: 2px;
    }
</style>