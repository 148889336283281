<template>
    <div class="home">
        <Loading v-if="loading"/>
        <perfect-scrollbar v-else class="" style="height: 100vh">
            <div class="d-flex justify-content-between p-2">
                <div>
                    <b-icon icon="info-circle" font-scale="1.7" class="mr-2" style="cursor: pointer" v-b-modal.modal-help/>
                    {{version}}
                </div>
                <div>
                    <b-form-select class="w-auto" v-model="$i18n.locale" :options="$i18n.availableLocales"
                                   @change="changeLocale">
                    </b-form-select>
                </div>
            </div>
            <div class="p-2 d-flex justify-content-around">
                <img v-bind:src="logoPath" alt="" class="logo">
            </div>
            <div class="p-2 d-flex justify-content-center ">
                <div class="card p-4 bg-white ">
                    <h2 class="h4 mb-3 font-weight-normal">{{$t('home.connection_with_code')}}</h2>
                    <b-form @submit="onSubmit">
                        <b-form-group
                                id="input-group-1" v-bind:label="$t('home.code_title')" label-for="input-1">
                            <b-form-input
                                    id="input-1"
                                    v-model="key.key"
                                    required
                                    v-bind:placeholder="$t('home.code')"
                                    autocomplete="off"
                            />
                        </b-form-group>
                        <b-form-group id="input-group-2" v-bind:label="$t('home.name_title')" label-for="input-2">
                            <b-form-input
                                    id="input-2"
                                    v-model="key.name"
                                    required
                                    v-bind:placeholder="$t('home.name')"
                                    autocomplete="off"
                            />
                        </b-form-group>
                        <b-form-group id="input-group-3" v-bind:label="$t('home.year_title')" label-for="input-3">
                            <b-form-input
                                    id="input-3"
                                    v-model="key.year"
                                    required
                                    v-bind:placeholder="$t('home.year')"
                                    autocomplete="off"
                            />
                        </b-form-group>
                        <div class="d-flex justify-content-center">
                            <b-button type="submit" variant="success">{{$t('home.connection')}}
                                <b-icon icon="unlock"/>
                            </b-button>
                        </div>

                    </b-form>
                </div>
            </div>
        </perfect-scrollbar>
        <modal-help/>
    </div>
</template>

<script>
    // @ is an alias to /src

    import {ConfJson, version} from "@/main";
    import Loading from "@/components/Loading"
    import router from "@/router";
    import ModalHelp from "@/components/ModalHelp";

    export default {

        name: 'Home',
        metaInfo: {
            title: 'Home',
            titleTemplate: '%s - Ines-RTC',
            htmlAttrs: {
                lang: localStorage.getItem('locale') || 'en',
                amp: true
            }
        },
        created: function () {
            let MyComponent = this;
            let myInterval = setInterval(function () {

                if (ConfJson != null) {
                    if (localStorage.getItem("s")) {
                        router.push("/live")
                    }

                    let old_lang = localStorage.getItem('locale')
                    if (old_lang === undefined || old_lang === null || old_lang === "null" || old_lang.length === 0) {
                        MyComponent.$i18n.locale = ConfJson.default_language
                        localStorage.setItem('locale', ConfJson.default_language)
                    }
                    //localStorage.setItem('locale', this.$i18n.locale)
                    MyComponent.logoPath = ConfJson.logo_path;
                    MyComponent.loading = false;
                    clearInterval(myInterval);
                }
            }, 100);
        },
        components: {
            Loading,
            ModalHelp,

        },
        data: function () {
            return {
                version: version,
                loading: true,
                logoPath: "",
                debug: false,
                accountName: localStorage.getItem("accountName"),
                form: {
                    email: '',
                    name: '',
                    food: null,
                    checked: []
                },
            }
        },
        computed: {
            key() {
                return this.$store.state.key.get_s
            },
        },
        methods: {
            onSubmit: function (evt) {
                evt.preventDefault();
                    this.$store.commit('key/getS')
            },
            changeLocale: function () {
                localStorage.setItem('locale', this.$i18n.locale)
            }
        }
    }
</script>

<style>
    img {
        max-width: 200px;
        max-height: 200px;
    }

    @media screen and (max-width: 600px) {
        img {
            max-width: 100px;
            max-height: 100px;
        }
    }

    label {
        margin-bottom: 0.2rem !important;
    }
</style>
