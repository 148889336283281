import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Live from '../views/Live.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {title: 'Home'},
  },  {
    path: '/live',
    name: 'Live',
    component: Live,
    meta: {title: 'Live'},
  },  {
    path: '/test',
    name: 'Test',
    component: Home,
    meta: {title: 'Test'},
  },

]

const router = new VueRouter({
  mode:'history',
  routes
})

export default router
