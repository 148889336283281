import $ from 'jquery'
import {ServiceUrl, ConfJson} from "@/main"
import router from "@/router";
import i18n from '@/i18n';
import DetectRTC from 'detectrtc';
//import adapter from 'adapterjs';


//mediaStream.getAudioTracks()[0].enabled = true; TODO: MUTE
//Use getTracks()[0].enabled = false instead. To unpause getTracks()[0].enabled = true // FOR VIDEO ?
//EXEMPLE : https://jsfiddle.net/jib1/p0jqedba/
const state = {
    loading: true,
    websocket: null,
    get_s: {
        "brand": '',
        "key": '',
        "name": '',
        "year": ''
    },
    rtc_connection: {
        auto_connect: false,
        on_trying: false,
        in_progress: false,
        connection_type: 'video',
        audio_input_values: [],
        audio_output_values: [],
        video_input_values: [],
        audio_input: localStorage.getItem('audio_input'),
        audio_output: localStorage.getItem('audio_output'),
        video_input: localStorage.getItem('video_input'),
        second_camera: false,
        pcRead: null,
        pcWrite: null,
        audio_track: null,
        video_track: null,
        audio_muted: true,
        video_muted: true,
        audio_sender: null,
        video_sender: null,
        status: i18n.t('live.status_0'),
        status_color: "primary",
        isMobileDevice: false,
        list_of_resolution: [],
        list_of_framerate: [],
        facing_mode: localStorage.getItem('facing_mode') || "user",
        currentResolution: null,
        framerate: "",
        firstFrameRate: "",
        lastResult: null,
        deletemaxBitrate: false,
        isMobileBroadband: false,
        checkMobileBroadband: false
    },
    sdp: {
        MySdp: "",
        SdpManager: ""
    },
    error: {
        isSafari: false,
        isFirefox: false,
        isChrome: false,
        isWrongBrowser: false,
        isWebsiteHasMicrophonePermissions: true,
        isWebsiteHasWebcamPermissions: true,
        isWebRTCSupported: true,
        hasWebcam: true,
        hasMicrophone: true,
        hasSpeakers: true,
    }
}

const mutations = {
    get200(state) {
        //state.rtc_connection.list_of_resolution = ConfJson.list_of_resolution//TODO add priority to resolution + order high to low
        //console.log(ConfJson.list_of_resolution)
        for (let i = 0; i < ConfJson.list_of_resolution.length; i++) {
            let res = ConfJson.list_of_resolution[i]
            res.priority = i
            state.rtc_connection.list_of_resolution.push(res)
        }
        state.rtc_connection.list_of_resolution.sort(compare)
        //console.log(JSON.stringify(state.rtc_connection.list_of_resolution))
        state.rtc_connection.list_of_framerate = ConfJson.list_of_framerate
        state.rtc_connection.firstFrameRate = ConfJson.list_of_framerate[0]
        get200();
        getDevice(state);
    }, getBandwidth(state) {
        /*console.log(state)
        return*/
        setInterval(function () {
            getBandwidth(state)
        }, 1000)
    }, getS(state) {
        $.get(ServiceUrl + "/public?", {
            "fnct": "get_s",
            "brand": ConfJson.brand,
            "name": state.get_s.name,
            "year": state.get_s.year,
            "random": state.get_s.key
        }).done(function (data) {
            localStorage.setItem("s", data)
            router.push('/live')
        }).fail(function () {
            alert(i18n.t('home.error_code'))
        }).always(function () {
        });
    }, setAutoConnect(state, autoConnect) {

        state.rtc_connection.auto_connect = localStorage.getItem('auto_connect') === "true" || autoConnect

    }, setVideo(state, value) {
        state.rtc_connection.video = value
    }, connect() {
        /*        if (!(state.rtc_connection.old_video_input === undefined || state.rtc_connection.old_video_input === null || state.rtc_connection.old_video_input === "null" || state.rtc_connection.old_video_input.length === 0) && (state.rtc_connection.video_input_values.length > 1)) {
                    state.rtc_connection.second_camera = true;
                }*/
        getEvent(state, function () {
            newConnectionRead(state)
        });

    }, changeAudioInput(state) {
        //state.rtc_connection.audio_input = state.rtc_connection.;
        localStorage.setItem('audio_input', state.rtc_connection.audio_input)
        state.audio_muted = false
        if (state.rtc_connection.in_progress) {
            state.rtc_connection.audio_track.stop();
            let constraints = {audio: ConfJson.constraints.audio, video: false}
            constraints.audio.deviceId = state.rtc_connection.audio_input

            navigator.mediaDevices.getUserMedia(constraints)
                .then(stream => {
                    document.getElementById('local-audio').srcObject = stream;
                    document.getElementById('local-audio').muted = true;
                    stream.getTracks().forEach(function (t) {
                        if (t.kind === "audio") {
                            state.rtc_connection.audio_track = t;
                            state.rtc_connection.audio_sender.replaceTrack(t);
                        }
                    });
                }).catch(function (err) {
                alert(i18n.t('live.get_media_failed'))
                if (ConfJson.debug) {
                    console.log(err.name + ": " + err.message);
                }
            });
        }
        //todo : if on connection > change stream source
    }, changeAudioOutput(state, value) {
        localStorage.setItem('audio_output', value)
    }, changeVideoInputState(state) {
        if (state.rtc_connection.isMobileDevice) {
            if (state.rtc_connection.facing_mode === "user") {
                state.rtc_connection.facing_mode = "environment"
            } else {
                state.rtc_connection.facing_mode = "user"
            }

        } else {
            for (let i = 0; i < state.rtc_connection.video_input_values.length; i++) {
                if (state.rtc_connection.video_input_values[i] !== state.rtc_connection.video_input) {
                    state.rtc_connection.video_input === state.rtc_connection.video_input_values[i]
                    break
                }
            }

        }

    }, changeVideoInput(state) {
        /*        let oldInput = localStorage.getItem("old_video_input")
                if (oldInput && oldInput.length !== 0 && state.rtc_connection.video_input === oldInput) {
                    localStorage.setItem('video_input', state.rtc_connection.video_input)
                    return
                }*/


        state.video_muted = false

        state.rtc_connection.second_camera = true;
        //let constraints = null;
        if (state.rtc_connection.isMobileDevice) {
            //state.sdp.SdpManager
            localStorage.setItem('facing_mode', state.rtc_connection.facing_mode)
        } else {
            localStorage.setItem('old_video_input', localStorage.getItem('video_input'));
            localStorage.setItem('video_input', state.rtc_connection.video_input)
        }

        /*CHECK RESOLUTION PART*/

        if (state.rtc_connection.in_progress) {
            document.getElementById('local-video').srcObject.getTracks().forEach(t => {if (t.kind === "video"){t.stop()}});
            state.rtc_connection.video_track.stop();
            //document.getElementById('local-video').srcObject.removeTrack()
            let constraints = getConstraints(state)//{audio:false,video:ConfJson.constraints.video}
            constraints.audio = false;

            navigator.mediaDevices.getUserMedia(constraints)
                .then(stream => {

                    document.getElementById('local-video').srcObject = stream;
                    document.getElementById('local-video').play();
                    $("#local-video[autoplay]").each(function(){ this.play(); });
                    document.getElementById('local-video').setAttribute("controls", true);
                    setTimeout(() => {
                        document.getElementById('local-video').removeAttribute("controls");
                    });
                    stream.getTracks().forEach(function (t) {
                        if (t.kind === "video") {
                            state.rtc_connection.video_muted = t.enabled;
                            //t.enabled;
                            state.rtc_connection.video_track = t;
                            state.rtc_connection.video_sender.replaceTrack(t);
                        }
                    });
                }).catch(function (err) {
                alert(i18n.t('live.get_media_failed'))
                if (ConfJson.debug) {
                    alert(err.name + ": " + err.message);
                }
            });
        }
    }, changeVideoParameter(state, isResolution) {
        changeResolutionAndFramerate(state, isResolution)
    },
    muteAudio(state) {
        document.getElementById('local-audio').srcObject.getTracks().forEach(function (t) {
            if (t.kind === "audio") {
                state.rtc_connection.audio_muted = !t.enabled;
                t.enabled = !t.enabled;
            }
        });
    }, muteVideo(state) {
        activeCamera(state)
    }, closeConnectionWebrtc(state) {
        $.post(ServiceUrl + "/public?", {
            "fnct": "close_s",
            "brand": ConfJson.brand,
            "s": localStorage.getItem("s")
        }).done(function () {
        }).fail(function () {
            localStorage.removeItem("s")
        }).always(function () {

            closeConnectionRead(state)
            state.rtc_connection.on_trying = false;
            state.rtc_connection.in_progress = false;
        });

    }
}

export default {
    namespaced: true,
    state,
    mutations
}

function handleError(error) {
    if (ConfJson.debug) {
        console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
    }
}

function getDevice(state) {
    DetectRTC.load(function () {


        if (ConfJson.debug) {
            console.log(DetectRTC)
        }
        state.error.isWebsiteHasMicrophonePermissions = DetectRTC.isWebsiteHasMicrophonePermissions;
        state.error.isWebsiteHasWebcamPermissions = DetectRTC.isWebsiteHasWebcamPermissions;
        state.error.isWebRTCSupported = DetectRTC.isWebRTCSupported;
        state.error.hasWebcam = DetectRTC.hasWebcam;
        state.error.hasMicrophone = DetectRTC.hasMicrophone;
        state.rtc_connection.isMobileDevice = DetectRTC.isMobileDevice;
        state.error.isSafari = DetectRTC.browser.isSafari
        state.error.isFirefox = DetectRTC.browser.isFirefox
        state.error.isChrome = DetectRTC.browser.isChrome

        if (!state.error.isSafari && !state.error.isFirefox && !state.error.isChrome) {
            state.error.isWrongBrowser = true;
            return
        }
        if (DetectRTC.hasSpeakers === false && (DetectRTC.browser.name === 'Chrome' || DetectRTC.browser.name === 'Edge')) {
            state.error.hasSpeakers = false;
        }
        if (!state.error.isWebRTCSupported) {
            return
        }
        if (state.error.isWebsiteHasMicrophonePermissions && state.error.isWebsiteHasWebcamPermissions) {
            getAllDevice(state)
        } else {
            let constraints = getConstraints(state)
            if (ConfJson.debug) {
                console.log(constraints)
            }

            navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
                getAllDevice(state)
                stream.getTracks().forEach(function (t) {
                    t.stop()
                });
            }).catch(function (err) {
                if (ConfJson.debug) {
                    console.log(err)
                }
                //alert(err)
                switch (err.constraint) {
                    case "width":
                        state.rtc_connection.list_of_resolution.shift();
                        state.rtc_connection.currentResolution = null;
                        if (state.rtc_connection.list_of_resolution.length > 0) {
                            getDevice(state)
                        } else {
                            alert(i18n.t('live.no_resolution'));
                        }
                        break
                    case "frameRate":
                        state.rtc_connection.list_of_framerate.push(state.rtc_connection.list_of_framerate.shift())
                        if (state.rtc_connection.list_of_framerate.length > 0 && state.rtc_connection.list_of_framerate[0] === state.rtc_connection.firstFrameRate) {
                            state.rtc_connection.list_of_resolution.shift();
                        }
                        //state.rtc_connection.list_of_framerate.shift();
                        state.rtc_connection.framerate = "";
                        if (state.rtc_connection.list_of_resolution.length > 0) {
                            getDevice(state)
                        } else {
                            alert(i18n.t('live.no_resolution'));
                        }
                        break
                    default:
                        state.rtc_connection.list_of_framerate.push(state.rtc_connection.list_of_framerate.shift())
                        if (state.rtc_connection.list_of_framerate.length > 0 && state.rtc_connection.list_of_framerate[0] === state.rtc_connection.firstFrameRate) {
                            state.rtc_connection.list_of_resolution.shift();
                        }
                        state.rtc_connection.currentResolution = null;
                        state.rtc_connection.framerate = "";
                        if (state.rtc_connection.list_of_resolution.length > 0) {
                            getDevice(state)
                        } else {
                            alert(i18n.t('live.no_resolution'));
                        }

                    //alert(i18n.t('live.error_on_constraints') + err);
                    //alert("Erreur dans les switch de constraints")
                }
            });
            /* navigator.mediaDevices.getUserMedia({audio: true, video: true}).then(function () {
                 getAllDevice(state)
             })*/
        }
    });
}

function getAllDevice(state) {
    /*
    * CHECK IF IT'S WIFI OR NOT
    * */
    let constraints = getConstraints(state)
    if (ConfJson.debug) {
        console.log(constraints)
    }

    navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
        state.rtc_connection.isMobileBroadband = true
        DetectRTC.DetectLocalIPAddress(function (ipAddress, isPublic, isIpv4) {
            if (!ipAddress) {
                return;
            }

            state.rtc_connection.checkMobileBroadband = true
            if (ipAddress.indexOf('Local') !== -1 || !isPublic) {
                //alert("WIFI!")
                state.rtc_connection.isMobileBroadband = false
                //state.
            } else {
                //alert("PUBLIQUE")
            }
            state.sdp.SdpManager += "ipAddress: " + ipAddress + "\n"
            state.sdp.SdpManager += "isPublic: " + isPublic + "\n"
            state.sdp.SdpManager += "isIpv4: " + isIpv4 + "\n"
            if (ConfJson.debug) {
                console.log(ipAddress)
                console.log(isPublic)
                console.log(isIpv4)
            }
        }, stream);


        DetectRTC.load(function () {
            state.error.isWebsiteHasMicrophonePermissions = DetectRTC.isWebsiteHasMicrophonePermissions;
            state.error.isWebsiteHasWebcamPermissions = DetectRTC.isWebsiteHasWebcamPermissions;
            state.error.isWebRTCSupported = DetectRTC.isWebRTCSupported;
            state.error.hasWebcam = DetectRTC.hasWebcam;
            state.error.hasMicrophone = DetectRTC.hasMicrophone;
            state.rtc_connection.isMobileDevice = DetectRTC.isMobileDevice;
            state.error.isSafari = DetectRTC.browser.isSafari;
            if (DetectRTC.hasSpeakers === false && (DetectRTC.browser.name === 'Chrome' || DetectRTC.browser.name === 'Edge')) {
                state.error.hasSpeakers = false;
            }
        });
        navigator.mediaDevices.enumerateDevices().then(function (info) {
            state.rtc_connection.audio_input_values = [];
            state.rtc_connection.audio_output_values = [];
            state.rtc_connection.video_input_values = [];
            let videoDone = false
            for (let i = 0; i !== info.length; ++i) {
                const deviceInfo = info[i];
                let value = deviceInfo.deviceId;
                let text = "";
                if (deviceInfo.kind === 'audioinput') {
                    text = deviceInfo.label || `microphone ${state.rtc_connection.audio_input_values.length + 1}`;
                    state.rtc_connection.audio_input_values.push({value, text});
                } else if (deviceInfo.kind === 'audiooutput') {
                    text = deviceInfo.label || `speaker ${state.rtc_connection.audio_output_values.length + 1}`;
                    state.rtc_connection.audio_output_values.push({value, text});
                } else if (deviceInfo.kind === 'videoinput') {
                    if (value === state.rtc_connection.video_input) {
                        videoDone = true
                    }
                    text = deviceInfo.label || `camera ${state.rtc_connection.video_input_values.length + 1}`;
                    state.rtc_connection.video_input_values.push({value, text});
                } else {
                    if (ConfJson.debug) {
                        console.log('Some other kind of source/device: ', deviceInfo);
                    }
                }
            }
            if (!videoDone) { //REMOVE THE OLD SAVED CAMERA
                localStorage.removeItem("video_input")
                state.rtc_connection.video_input = null
            }

            if (state.rtc_connection.audio_input === undefined || state.rtc_connection.audio_input === null || state.rtc_connection.audio_input === "null" || state.rtc_connection.audio_input.length === 0) {
                //localStorage.setItem('audio_input', state.rtc_connection.audio_input_values[0].value)
                for (let i = 0; i < state.rtc_connection.audio_input_values.length; i++) {
                    if (state.rtc_connection.audio_input_values[i].value === "default") {
                        state.rtc_connection.audio_input = state.rtc_connection.audio_input_values[i].value
                        break
                    }
                }
                if (state.rtc_connection.audio_input !== "default") {
                    state.rtc_connection.audio_input = "default"
                }
                //state.rtc_connection.audio_input = state.rtc_connection.audio_input_values[0].value;
            }
            /*        if (state.rtc_connection.audio_output === undefined || state.rtc_connection.audio_output === null || state.rtc_connection.audio_output === "null" || state.rtc_connection.audio_output.length === 0) {
                        //localStorage.setItem('audio_output', state.rtc_connection.audio_output_values[0].value)
                        state.rtc_connection.audio_output = state.rtc_connection.audio_output_values[0].value;
                    }*/


            if (state.rtc_connection.video_input === undefined || state.rtc_connection.video_input === null || state.rtc_connection.video_input === "null" || state.rtc_connection.video_input.length === 0) {
                localStorage.setItem('video_input', state.rtc_connection.video_input_values[0].value)
                state.rtc_connection.video_input = state.rtc_connection.video_input_values[0].value;
            }

            if (state.rtc_connection.video_input_values.length > 1) {
                state.rtc_connection.second_camera = true;
                let oldVideoInput = localStorage.getItem("old_video_input")
                if ((oldVideoInput === undefined || oldVideoInput === null || oldVideoInput === "null" || oldVideoInput.length === 0)) {

                    localStorage.setItem('old_video_input', state.rtc_connection.video_input_values[1].value)
                }
            }

            state.loading = false;
            if (state.rtc_connection.auto_connect) {
                getEvent(state, function () {
                    newConnectionRead(state);
                });
            }
        }).catch(handleError);
        stream.getTracks().forEach(function (t) {
            t.stop()
        });

    }).catch(function (err) {
        if (ConfJson.debug) {
            console.log(err)
        }
        //alert(err)
        switch (err.constraint) {
            case "width":
                state.rtc_connection.list_of_resolution.shift();
                state.rtc_connection.currentResolution = null;
                if (state.rtc_connection.list_of_resolution.length > 0) {
                    getAllDevice(state)
                } else {
                    alert(i18n.t('live.no_resolution'));
                }
                break
            case "frameRate":
                state.rtc_connection.list_of_framerate.push(state.rtc_connection.list_of_framerate.shift())
                if (state.rtc_connection.list_of_framerate.length > 0 && state.rtc_connection.list_of_framerate[0] === state.rtc_connection.firstFrameRate) {
                    state.rtc_connection.list_of_resolution.shift();
                }
                //state.rtc_connection.list_of_framerate.shift();
                state.rtc_connection.framerate = "";
                if (state.rtc_connection.list_of_resolution.length > 0) {
                    getAllDevice(state)
                } else {
                    alert(i18n.t('live.no_resolution'));
                }
                break
            default:
                state.rtc_connection.list_of_framerate.push(state.rtc_connection.list_of_framerate.shift())
                if (state.rtc_connection.list_of_framerate.length > 0 && state.rtc_connection.list_of_framerate[0] === state.rtc_connection.firstFrameRate) {
                    state.rtc_connection.list_of_resolution.shift();
                }
                state.rtc_connection.currentResolution = null;
                state.rtc_connection.framerate = "";
                if (state.rtc_connection.list_of_resolution.length > 0) {
                    getAllDevice(state)
                } else {
                    alert(i18n.t('live.no_resolution'));
                }

            //alert(i18n.t('live.error_on_constraints') + err);
            //alert("Erreur dans les switch de constraints")
        }
    });

}


function newConnectionRead(state) {

    get200();
    //TRYING

    state.rtc_connection.on_trying = true;
    state.rtc_connection.status = i18n.t('live.status_5')
    state.rtc_connection.status_color = "warning"
    if (ConfJson != null && ConfJson) {
        $.getJSON(ServiceUrl + "/public?", {
            "fnct": "get_ice",
            "s": localStorage.getItem('s'),
            "brand": ConfJson.brand
        }).done(function (data) {
            //console.log(data)
            state.rtc_connection.pcRead = new RTCPeerConnection({
                iceServers: data.ice
            });

            state.rtc_connection.pcRead.oniceconnectionstatechange = function (/*e*/) {
                log(state, state.rtc_connection.pcRead.iceConnectionState);
            }
            /*            let TO = null;
                        let listener;
                        state.rtc_connection.pcRead.addEventListener('icecandidate', listener = () => {
                            if (TO != null) {
                                clearTimeout(TO)
                            } else if (TO === "OK") {
                                return
                            }
                            TO = setTimeout(function () {
                                state.rtc_connection.pcRead.removeEventListener('icecandidate', listener);
                                TO = "OK";
                                connectCaller(state);
                            }, 2000);
                        });*/
            state.rtc_connection.pcRead.onicecandidate = function (event) {
                if (event.candidate) {
                    if (event.candidate.candidate === '') {
                        return;
                    } else {
                        sendEventToWebsocket(state, "set_ice_read", JSON.stringify(event.candidate))
                        //sendEventToWebsocket(state, "set_ice_read", event.candidate.candidate)
                    }
                }
            }

            if (navigator.mediaDevices === undefined) {
                navigator.mediaDevices = {};
            }

            DetectRTC.load(function () {
                state.error.isWebsiteHasMicrophonePermissions = DetectRTC.isWebsiteHasMicrophonePermissions;
                state.error.isWebsiteHasWebcamPermissions = DetectRTC.isWebsiteHasWebcamPermissions;
                state.error.isWebRTCSupported = DetectRTC.isWebRTCSupported;
                state.error.hasWebcam = DetectRTC.hasWebcam;
                state.error.hasMicrophone = DetectRTC.hasMicrophone;
                state.rtc_connection.isMobileDevice = DetectRTC.isMobileDevice;
                state.error.isSafari = DetectRTC.browser.isSafari;
                if (DetectRTC.hasSpeakers === false && (DetectRTC.browser.name === 'Chrome' || DetectRTC.browser.name === 'Edge')) {
                    state.error.hasSpeakers = false;
                }
                let myInterval = setInterval(function () {
                    //TODO : METTRE EN VARIABLE LES 15 et 700 des minimums si 4G
                    if (state.rtc_connection.checkMobileBroadband) {
                        if (state.rtc_connection.isMobileBroadband) {//detect 3g/4g/5g delete high resolution
                            //SELECT THE LOWEST FRAMERATE
                            for (let i = 0; i < state.rtc_connection.list_of_framerate.length; i++) {
                                if (parseInt(state.rtc_connection.list_of_framerate[i]) < 15) {
                                    state.rtc_connection.framerate = parseInt(state.rtc_connection.list_of_framerate[i])
                                    break
                                }
                            }

                            //Clear all HIGH resolution
                            for (; ;) {
                                let retry = false
                                for (let i = 0; i < state.rtc_connection.list_of_resolution.length; i++) {
                                    let width = parseInt(state.rtc_connection.list_of_resolution[i].resolution.split("x")[0])
                                    if (width > 700) {
                                        state.rtc_connection.list_of_resolution.splice(i, 1);
                                        retry = true
                                        break
                                    }
                                }
                                if (!retry) {
                                    break
                                }
                            }
                            state.rtc_connection.isMobileBroadband = false //To loop in resolution just 1 time !
                        }
                        clearInterval(myInterval)
                        getBestUserMedia(state)
                    }
                }, 500)

            });
        }).fail(function (e) {
            if (ConfJson.debug) {
                console.log(e)
            }
        }).always(function () {
        });
    }
}

function getConstraints(state) {


    let returnConstraints = JSON.parse(JSON.stringify(ConfJson.constraints))
    if (ConfJson.debug) {
        console.log(returnConstraints)
    }

    for (let i = 0; i < state.rtc_connection.audio_input_values.length; i++) {
        if (state.rtc_connection.audio_input_values[i].value === state.rtc_connection.audio_input) {
            returnConstraints.audio.deviceId = state.rtc_connection.audio_input
        }
    }
    if (state.rtc_connection.isMobileDevice) {
        delete returnConstraints.video.deviceId
        returnConstraints.video.facingMode = {}
        returnConstraints.video.facingMode.exact = state.rtc_connection.facing_mode
    } else {
        let videoDone = false
        for (let i = 0; i < state.rtc_connection.video_input_values.length; i++) {
            if (state.rtc_connection.video_input_values[i].value === state.rtc_connection.video_input) {
                videoDone = true;
                returnConstraints.video.deviceId = state.rtc_connection.video_input
            }
        }
        if (!videoDone) {
            returnConstraints.video.deviceId = state.rtc_connection.video_input
            //state.rtc_connection.video_input = state.rtc_connection.video_input_values[0]
            //returnConstraints.video.deviceId = state.rtc_connection.video_input_values[0]
        }
    }
    if (ConfJson.adaptative_resolution) {
        return returnConstraints
    }
    if (state.rtc_connection.list_of_framerate.length === 0) {
        alert(i18n.t('live.no_framerate'));
        //alert("no framerate supported ! ")
        return
    }
    if (state.rtc_connection.framerate.length === 0) {
        state.rtc_connection.framerate = parseInt(state.rtc_connection.list_of_framerate[0])
    }
    //console.log(returnConstraints)
    returnConstraints.video.frameRate = {}
    returnConstraints.video.frameRate = state.rtc_connection.framerate
    //returnConstraints.video.frameRate.exact = state.rtc_connection.framerate
    if (state.rtc_connection.list_of_resolution.length === 0) {
        alert(i18n.t('live.no_resolution'));
        //alert("no resolution supported ! ")
        return
    }
    if (state.rtc_connection.currentResolution === null) {

        /*for (let i =0;i< state.rtc_connection.list_of_resolution.length; i++) {
            if (state.rtc_connection.currentResolution === null || state.rtc_connection.list_of_resolution[i].priority < state.rtc_connection.currentResolution.priority){
                state.rtc_connection.currentResolution = state.rtc_connection.list_of_resolution[i]
            }
        }*/
        state.rtc_connection.currentResolution = state.rtc_connection.list_of_resolution[0]
    }
    returnConstraints.video.width = {}
    returnConstraints.video.height = {}
    let Resolution = state.rtc_connection.currentResolution.resolution.split("x");
    returnConstraints.video.width.exact = parseInt(Resolution[0]);
    returnConstraints.video.height.exact = parseInt(Resolution[1]);
    //returnConstraints.video.resizeMode = "none"
    //returnConstraints.video.width = parseInt(Resolution[0]);
    //returnConstraints.video.height = parseInt(Resolution[1]);
    //returnConstraints.video.aspectRatio.exact = 1.7777777778
    /*    returnConstraints.video = {}
        returnConstraints.video.width = {}
        returnConstraints.video.height = {}
        //returnConstraints.video.frameRate = {}
        returnConstraints.video.width = parseInt(Resolution[0]);
        returnConstraints.video.height = parseInt(Resolution[1]);
        //returnConstraints.video.frameRate.exact = 15*/

    /*
    *                 "aspectRatio": {
                        "exact": 1.7777777778
                    }*/
    return returnConstraints

}

function changeResolutionAndFramerate(state, isResolution) {
    if (state.rtc_connection.in_progress && !ConfJson.adaptative_resolution) {
        let constraints = getConstraints(state)

        //console.log(constraints)
        state.rtc_connection.video_track.applyConstraints(constraints.video)
            .then(() => {
                state.rtc_connection.audio_sender.replaceTrack(state.rtc_connection.audio_track);
                state.rtc_connection.video_sender.replaceTrack(state.rtc_connection.video_track);
                //$('.info').text(JSON.stringify(state.rtc_connection.video_track.getSettings(), null, 4))
            })
            .catch(function (reason) {
                switch (reason.constraint) {
                    case "width":
                        alert(i18n.t('live.no_resolution2'));
                        break
                    case "frameRate":
                        alert(i18n.t('live.no_framerate2'));
                        break
                    default:
                        alert(i18n.t('live.error_on_constraints') + reason);
                    //alert("Erreur dans les switch de constraints")
                }
//                    alert(i18n.t('live.error_on_constraints') + reason);
                /*alert(reason.message + " (" + reason.constraint + ")");
                console.log(reason)*/
            })
        if (isResolution) {
            sendEventToWebsocket(state, "resolution", state.rtc_connection.currentResolution.resolution)
        } else {
            sendEventToWebsocket(state, "framerate", '' + state.rtc_connection.framerate)
        }
    }

}

function activeCamera(state) {
    document.getElementById('local-video').srcObject.getTracks().forEach(function (t) {
        if (t.kind === "video") {
            state.rtc_connection.video_muted = !t.enabled;
            t.enabled = !t.enabled;
        }
    });
    sendEventToWebsocket(state, "camera", '' + state.rtc_connection.video_muted)
}

function getBestUserMedia(state) { //IICII
    let constraints = getConstraints(state)
    if (ConfJson.debug) {
        console.log(constraints)
    }

    navigator.mediaDevices.getUserMedia(constraints)
        .then(stream => {
                state.rtc_connection.in_progress = true;
                state.rtc_connection.pcRead.addTransceiver('audio', {'direction': 'sendrecv'});//sendrecv//sendonly
                state.rtc_connection.status_color = "primary";
                state.rtc_connection.status = i18n.t('live.status_4');
                state.rtc_connection.pcRead.addTransceiver('video', {'direction': 'sendrecv'});
                $("#audio-btn").addClass("d-none");
                $("#video-btn").text("").append("<span class=\"spinner-border spinner-border-sm\" role=\"status\" aria-hidden=\"true\"></span>\n" +
                    "  Loading...");
                /*
                                } else {
                                    $("#video-btn").addClass("d-none");
                                    $("#audio-btn").text("").append("<span class=\"spinner-border spinner-border-sm\" role=\"status\" aria-hidden=\"true\"></span>\n" +
                                        "  Loading...");
                                }*/

                state.rtc_connection.pcRead.createOffer().then(function (offer) {

                    state.sdp.MySdp = offer.sdp
                    offer.sdp = setVideoBitrates(offer.sdp, {
                        min: 500,
                        max: 5000000
                    });
                    if (ConfJson.debug) {
                        console.log(JSON.stringify(offer.sdp))
                    }
                    //offer.sdp = removeBandwidthRestriction(offer.sdp)
                    state.rtc_connection.pcRead.setLocalDescription(offer);
                    let el = $("#audio-btn");
                    if (el.hasClass("d-none")) {
                        el = $("#video-btn");
                    }
                    /*
                    * PUSH TO MANAGER ALL SPEC OF THIS CONNECTION !
                    * */
                    let listOfResolution = []
                    for (let i = 0; i < state.rtc_connection.list_of_resolution.length; i++) {
                        listOfResolution.push(state.rtc_connection.list_of_resolution[i].resolution)
                    }
                    sendEventToWebsocket(state, "all_resolution", btoa(JSON.stringify(listOfResolution)));
                    sendEventToWebsocket(state, "all_framerate", btoa(JSON.stringify(state.rtc_connection.list_of_framerate)));
                    sendEventToWebsocket(state, "resolution", state.rtc_connection.currentResolution.resolution)
                    sendEventToWebsocket(state, "framerate", '' + state.rtc_connection.framerate)
                    sendEventToWebsocket(state, "camera", '' + state.rtc_connection.video_muted)

                    /*                    if ((adapter.browserDetails.browser === 'chrome' ||
                                            adapter.browserDetails.browser === 'safari' ||
                                            (adapter.browserDetails.browser === 'firefox' &&
                                                adapter.browserDetails.version >= 64)) &&
                                            'RTCRtpSender' in window &&
                                            'setParameters' in window.RTCRtpSender.prototype) {*/

                    /*                        let senders = state.rtc_connection.pcRead.getSenders();
                                            //console.log(senders)
                                            for (let i = 0; i < senders.length; i++) {
                                                let sender = senders[i]
                                                //console.log(sender)
                                                if (sender.track.kind === "video") {
                                                    let params = sender.getParameters();
                                                    console.log(params)
                                                    if (!params.encodings) {
                                                        params.encodings = [{}];
                                                    }
                                                    console.log(params.encodings[0])
                                                    console.log(params.encodings[0].maxBitrate)
                                                    //params.encodings[0].maxBitrate = 5000000;
                                                    sender.setParameters(params);
                                                }
                                            }*/
                    //}

                    el.text("").append("<span class=\"spinner-border spinner-border-sm\" role=\"status\" aria-hidden=\"true\"></span>\n" +
                        i18n.t('live.waiting_answer'));
                    sendEventToWebsocket(state, "set_b64_read", btoa(JSON.stringify(offer)))
                }).catch(function (reason) {
                    if (ConfJson.debug) {
                        alert(reason);
                    }
                    log(state, reason)
                });

                stream.getTracks().forEach(function (track) {
                    if (track.kind === "audio") {
                        state.rtc_connection.audio_track = track;
                        document.getElementById('local-audio').srcObject = stream;
                        document.getElementById('local-audio').muted = true;
                        state.rtc_connection.audio_sender = state.rtc_connection.pcRead.addTrack(track, stream);
                    } else if (track.kind === "video") {
                        state.rtc_connection.video_track = track;
                        document.getElementById('local-video').srcObject = stream;
                        document.getElementById('local-video').play();
                        $("#local-video[autoplay]").each(function(){ this.play(); });
                        document.getElementById('local-video').setAttribute("controls", true);
                        setTimeout(() => {
                            document.getElementById('local-video').removeAttribute("controls");
                        });
                        state.rtc_connection.video_sender = state.rtc_connection.pcRead.addTrack(track, stream);
                        let preferedResolution = null
                        for (let i = 0; i < state.rtc_connection.list_of_resolution.length; i++) {
                            if (preferedResolution === null || state.rtc_connection.list_of_resolution[i].priority < preferedResolution.priority) {
                                preferedResolution = state.rtc_connection.list_of_resolution[i]
                            }
                        }
                        if (preferedResolution != null) {
                            state.rtc_connection.currentResolution = preferedResolution
                            changeResolutionAndFramerate(state, true)//IICII
                        }


                    }
                });
            }
        ).catch(function (err) {
        if (ConfJson.debug) {
            console.log(err)
        }

        //alert(err)
        switch (err.constraint) {
            case "width":
                state.rtc_connection.list_of_resolution.shift();
                state.rtc_connection.currentResolution = null;
                if (state.rtc_connection.list_of_resolution.length > 0) {
                    getBestUserMedia(state)
                } else {
                    alert(i18n.t('live.no_resolution'));
                }
                break
            case "frameRate":
                state.rtc_connection.list_of_framerate.push(state.rtc_connection.list_of_framerate.shift())
                if (state.rtc_connection.list_of_framerate.length > 0 && state.rtc_connection.list_of_framerate[0] === state.rtc_connection.firstFrameRate) {
                    state.rtc_connection.list_of_resolution.shift();
                }
                //state.rtc_connection.list_of_framerate.shift();
                state.rtc_connection.framerate = "";
                if (state.rtc_connection.list_of_resolution.length > 0) {
                    getBestUserMedia(state)
                } else {
                    alert(i18n.t('live.no_resolution'));
                }
                break
            default:
                state.rtc_connection.list_of_framerate.push(state.rtc_connection.list_of_framerate.shift())
                if (state.rtc_connection.list_of_framerate.length > 0 && state.rtc_connection.list_of_framerate[0] === state.rtc_connection.firstFrameRate) {
                    state.rtc_connection.list_of_resolution.shift();
                }
                state.rtc_connection.currentResolution = null;
                state.rtc_connection.framerate = "";
                if (state.rtc_connection.list_of_resolution.length > 0) {
                    getBestUserMedia(state)
                } else {
                    alert(i18n.t('live.no_resolution'));
                }

            //alert(i18n.t('live.error_on_constraints') + err);
            //alert("Erreur dans les switch de constraints")
        }

    });
}

function get200() {
    $.get(ServiceUrl + "/public?", {
        "fnct": "get_200",
        "brand": ConfJson.brand,
        "s": localStorage.getItem('s'),
    }).done(function () {
    }).fail(function () {
        goHome()
    }).always(function () {
    });
}

function goHome() {
    localStorage.removeItem("s");
    router.go('/')
}

function log(state, msg) {
    switch (msg) {
        case "new":
            state.rtc_connection.status_color = "primary";
            state.rtc_connection.status = i18n.t('live.status_1');
            break;
        case "checking":
            state.rtc_connection.status_color = "primary";
            state.rtc_connection.status = i18n.t('live.status_2');
            break;
        case "connected":
            state.rtc_connection.status_color = "success";
            state.rtc_connection.status = i18n.t('live.status_3');
            break;
        case "completed":
            state.rtc_connection.status_color = "success";
            state.rtc_connection.status = i18n.t('live.status_3');
            break;
        case "failed":
            /*state.status_color = "danger";
            state.status = i18n.t('live.status_5');*/
            closeConnectionRead(state);
            location.reload();
            //status.attr('data-original-title', 'Echec de la connexion').removeClass("bg-danger bg-success bg-primary").addClass("bg-danger");
            break;
        case "disconnected":
            state.rtc_connection.status_color = "danger";
            state.rtc_connection.status = i18n.t('live.status_6');
            break;
        case "closed":
            state.rtc_connection.status_color = "danger";
            state.rtc_connection.status = i18n.t('live.status_7');
            break;
        case "no_device":
            state.rtc_connection.status_color = "danger";
            state.rtc_connection.status = i18n.t('live.status_8');
            break;
        default :
            state.rtc_connection.status_color = "danger";
            state.rtc_connection.status = msg;
            break;
    }

}

function closeConnectionRead(state) {
    /*    myStream.getTracks().forEach(function (track) {
            track.stop();
        });*/
    if (state.rtc_connection.pcRead != null) {
        state.rtc_connection.pcRead.close();
        state.rtc_connection.pcRead = null;
    }
    if (state.rtc_connection.pcWrite != null) {
        state.rtc_connection.pcWrite.close();
        state.rtc_connection.pcWrite = null;
    }

}

/*
function connectCaller(state) {

    let s = localStorage.getItem("s");
    //s = document.getElementById('s-value').innerText;
    if (s.length === 0 || state.rtc_connection.pcRead == null) {
        alert(i18n.t('live.invalid_connection'))
        return;
    }
    let el = $("#audio-btn");
    if (el.hasClass("d-none")) {
        el = $("#video-btn");
    }

}*/

/*
function getStatus(state) {
    console.log("#################### GET STATUS ####################")
    $.get(ServiceUrl + "/public?", {
        "fnct": "get_status",
        "brand": ConfJson.brand,
        "s": localStorage.getItem("s")
    }).done(function (data) {
            //alert(data)
            if (data === "RELOAD") {
                closeConnectionRead(state);
                newConnectionRead(state);
            } else if (data === "RESET") {
                closeConnectionRead(state);
                location.reload();
            } else if (data === "NEW_INCOMMING_SRC") {
                if (state.rtc_connection.pcWrite != null) {
                    state.rtc_connection.pcWrite.close();
                    state.rtc_connection.pcWrite = null;
                    /!*                    document.getElementById("remote-audio").srcObject = ""
                                        document.getElementById("remote-video").srcObject = ""*!/
                }

                newConnectionWrite(state, function () {
                    getStatus(state);
                });

            } else if (data === "OK") {
                /!*time out 10 sec no change*!/
                getStatus(state)
            } else {
                closeConnectionRead(state);
                goHome()
            }
        }
    ).fail(function (e) {
        console.log("##### ERROR get_status");
        console.log(e);
        if (ConfJson.debug) {
            alert("get_status failed");
        }

        goHome()
    }).always(function () {

    });
}*/


function newConnectionWrite(state) {

    //console.log(ConfJson.ice)ServiceUrl + "/public?" // "brand": ConfJson.brand,"s": localStorage.getItem('s'),
    $.getJSON(ServiceUrl + "/public?", {
        "fnct": "get_ice",
        "s": localStorage.getItem('s'),
        "brand": ConfJson.brand
    }).done(function (data) {
        if (ConfJson.debug) {
            console.log(data.ice)
        }
        state.rtc_connection.pcWrite = new RTCPeerConnection({
            iceServers: data.ice
        });
        state.rtc_connection.pcWrite.oniceconnectionstatechange = function () {
            if (ConfJson.debug) {
                console.log(state.rtc_connection.pcWrite.iceConnectionState);
            }
        };
        state.rtc_connection.pcWrite.ontrack = function (event) {
            if (event.track.kind === "audio") {
                document.getElementById('remote-audio').srcObject = event.streams[0];
            } else {
                document.getElementById('remote-video').srcObject = event.streams[0];
                document.getElementById('remote-video').muted = true;
            }
        };

        /*        let TO = null;
                let listener
                state.rtc_connection.pcWrite.addEventListener('icecandidate', listener = () => {
                    //console.log(event)
                    if (TO != null) {
                        clearTimeout(TO)
                    } else if (TO === "OK") {
                        return
                    }
                    TO = setTimeout(function () {
                        state.rtc_connection.pcWrite.removeEventListener('icecandidate', listener);
                        TO = "OK";
                        getNewStreamSrc(state, callback);
                    }, 2000);
                });*/
        state.rtc_connection.pcWrite.onicecandidate = function (event) {
            if (event.candidate) {
                if (event.candidate.candidate === '') {
                    return;
                } else {
                    sendEventToWebsocket(state, "set_ice_write", JSON.stringify(event.candidate))
                    //sendEventToWebsocket(state, "set_ice_write", event.candidate.candidate)
                }
            }
        }
        state.rtc_connection.pcWrite.addTransceiver('audio', {'direction': 'sendrecv'});//sendrecv//recvonly
        state.rtc_connection.pcWrite.addTransceiver('video', {'direction': 'sendrecv'});

        state.rtc_connection.pcWrite.createOffer().then(function (offer) {
            state.rtc_connection.pcWrite.setLocalDescription(offer);
            /* state.rtc_connection.pcWrite.setLocalDescription(offer).catch(function (e) {
                 console.log(e)
             });*/
            sendEventToWebsocket(state, "set_b64_write", btoa(JSON.stringify(offer)))
        }).catch(function (reason) {
            if (ConfJson.debug) {
                alert(reason);
            }
        });

    }).fail(function (e) {
        if (ConfJson.debug) {
            console.log(e)
        }
    }).always(function () {

    });


}

/*
function getNewStreamSrc(state, callback) {

    let s = localStorage.getItem("s");
    if (!s || s.length === 0 || state.rtc_connection.pcWrite == null) {
        //alert("token is absent or PC is null!");
        return;
    }
    $.post(ServiceUrl + "/public?", {
        "fnct": "set_b64_caller_write",
        "brand": ConfJson.brand,
        "s": s,
        "b64": btoa(JSON.stringify(state.rtc_connection.pcWrite.localDescription))
    }).done(function (data) {
        if (data.length == 0 || data === "error") {
            goHome()
            //location.reload();
        } else if (data === "OK") {

            getNewStreamSrc(state, callback);
            return;
        } else {
            state.rtc_connection.pcWrite.setRemoteDescription(new RTCSessionDescription(JSON.parse(atob(data))));
            let ts = new Date();
            console.log(ts.toTimeString() + "connected WRITE");
            if (callback !== undefined && callback !== null)
                callback();
        }
    }).fail(function () {
        goHome()
    }).always(function () {

    });
}*/

/*SET BANDWIDTH */

function setVideoBitrates(sdp, params) {

    let allowedCodec = ['VP8/90000', 'VP9/90000', 'H264/90000']
    let codecIndex;
    params = params || {};
    let xgoogle_min_bitrate = params.min;
    let xgoogle_max_bitrate = params.max;

    let lineModifier = '\r\nb=AS:' + xgoogle_max_bitrate;
    /*    if (params.isFirefox || params.isSafari) {
            xgoogle_max_bitrate = (xgoogle_max_bitrate >>> 0) * 1000;
            lineModifier = '\r\nb=TIAS:' + xgoogle_max_bitrate;
        }*/
    let sdpLines = sdp.split('\r\n');
    sdpLines.forEach((str, index) => {
        if (/^a=mid:(1|video)/.test(str)) {
            //sdpLines[index] += '\r\nb=AS:' + xgoogle_max_bitrate;
            sdpLines[index] += lineModifier
        }
    });
    for (let i = 0; i < allowedCodec.length; i++) {
        codecIndex = 0
        for (; ;) {
            codecIndex++;
            codecIndex = findLineInRange(sdpLines, codecIndex, -1, 'a=rtpmap', allowedCodec[i]);
            let codecPayload;
            if (codecIndex) {
                codecPayload = getCodecPayloadType(sdpLines[codecIndex]);
            }

            if (!codecPayload) {
                break;
            }
            let rtxIndex = findLineInRange(sdpLines, codecIndex, -1, 'a=rtpmap', 'rtx/90000');
            let rtxPayload;
            if (rtxIndex) {
                rtxPayload = getCodecPayloadType(sdpLines[rtxIndex]);
            }

            if (!rtxIndex) {
                return sdp;
            }

            let rtxFmtpLineIndex = findLineInRange(sdpLines, 0, -1, 'a=fmtp:' + rtxPayload.toString());
            if (rtxFmtpLineIndex !== null) {
                let appendrtxNext = '\r\n';
                appendrtxNext += 'a=fmtp:' + codecPayload + ' x-google-min-bitrate=' + (xgoogle_min_bitrate || '228') + '; x-google-max-bitrate=' + (xgoogle_max_bitrate || '228');
                sdpLines[rtxFmtpLineIndex] = sdpLines[rtxFmtpLineIndex].concat(appendrtxNext);
                sdp = sdpLines.join('\r\n');
            }
        }

    }
    return sdp;

}

/*
function removeBandwidthRestriction(sdp) {
    return sdp.replace(/b=AS:.*\r\n/, '').replace(/b=TIAS:.*\r\n/, '');
}*/

function getBandwidth(state) {

    if (!state.rtc_connection.pcRead || state.rtc_connection.status_color !== "success") {
        return
    }
        let senders = state.rtc_connection.pcRead.getSenders();

        for (let i = 0; i < senders.length; i++) {
            let sender = senders[i]
            //console.log(sender)
            if (sender.track.kind === "video") {

                if (!sender) {
                    return;
                }
                const parameters = sender.getParameters();
                if (!parameters.encodings) {
                    //console.log("PAS DE LIMITE ! ")
                    //parameters.encodings = [{}];
                } else if (!state.rtc_connection.deletemaxBitrate) {
                    //console.log("MAX BW:" + parameters.encodings[0].maxBitrate)
                    //delete parameters.encodings[0].maxBitrate;
                    //parameters.encodings = [{}];
                    //let senders = state.rtc_connection.pcRead.getSenders();
                    //console.log(senders)


                    if (!parameters.encodings) {
                        parameters.encodings = [{}];
                    }
                    //console.log(parameters.encodings)
                    //console.log(parameters.encodings[0])
                    //console.log(parameters.encodings[0].maxBitrate)
                    //console.log(parameters.encodings[0].networkPriority)
                    parameters.encodings[0].networkPriority = "high";
                    parameters.encodings[0].priority = "high";
                    parameters.encodings[0].maxBitrate = 5000000;
                    sender.setParameters(parameters);


                    //sender.setParameters(parameters)
                    state.rtc_connection.deletemaxBitrate = true
                    //console.log(" LIMITE  DE BW ! ")
                }
                //console.log(parameters.encodings)
                sender.getStats().then(res => {
                    res.forEach(report => {
                        let bytes;

                        if (report.type === 'outbound-rtp') {
                            if (report.isRemote) {
                                return;
                            }
                            const now = report.timestamp;
                            bytes = report.bytesSent;
                            //packets = report.packetsSent;
                            if (state.rtc_connection.lastResult && state.rtc_connection.lastResult.has(report.id)) {
                                // calculate bitrate
                                const bitrate = 8 * (bytes - state.rtc_connection.lastResult.get(report.id).bytesSent) /
                                    (now - state.rtc_connection.lastResult.get(report.id).timestamp);

                                $("#bandwidth").html(parseInt(bitrate) + " kbps")
                                sendEventToWebsocket(state, "bandwidth", parseInt(bitrate) + " kbps")
                                //applyNewResolution(state, parseInt(bitrate + headerrate))
                            }
                        }
                    });
                    state.rtc_connection.lastResult = res;
                });
            }

        }

}

/*
function applyNewResolution(state, bitrate) {
    let changeRes = false
    for (let i = 0; i < state.rtc_connection.list_of_resolution.length; i++) {
        if (state.rtc_connection.list_of_resolution[i].min_bandwidth < bitrate && state.rtc_connection.list_of_resolution[i].min_bandwidth !== state.rtc_connection.currentResolution.min_bandwidth) {
            //alert("change the resolution to lower ! ")
            changeRes = true;
            state.rtc_connection.currentResolution = state.rtc_connection.list_of_resolution[i]
            break;
        }
    }
    if (changeRes) {
        //alert("Change THE RESOLUTION ! "+ bitrate + " MIN : " + state.rtc_connection.currentResolution.min_bandwidth)
        let constraints = getConstraints(state)
        state.rtc_connection.video_track.applyConstraints(constraints.video)
            .then(() => {
                //$('.info').text(JSON.stringify(state.rtc_connection.video_track.getSettings(), null, 4))
            })
            .catch(function (reason) {
                console.log(reason)
            })
    }



}*/


function findLineInRange(sdpLines, startLine, endLine, prefix, substr) {
    //alert(prefix)
    var realEndLine = endLine !== -1 ? endLine : sdpLines.length;
    for (var i = startLine; i < realEndLine; ++i) {
        if (sdpLines[i].indexOf(prefix) === 0) {
            if (!substr ||
                sdpLines[i].toLowerCase().indexOf(substr.toLowerCase()) !== -1) {
                return i;
            }
        }
    }
    return null;
}

function getCodecPayloadType(sdpLine) {
    var pattern = new RegExp('a=rtpmap:(\\d+) \\w+\\/\\d+');
    var result = sdpLine.match(pattern);
    return (result && result.length === 2) ? result[1] : null;
}


/*
*   WEBSOCKET EVENT PART !
* */

function getEvent(state, callback) {
    let wsURL = ConfJson.websocket_url
    if (wsURL.length === 0) {
        wsURL = window.location.origin.replace('http', 'ws')
    }
    wsURL += '/public_ws'
    if (ConfJson.debug) {
        console.log("trying to connect in : " + wsURL)
    }
    state.websocket = new WebSocket(wsURL);

    state.websocket.onerror = function (event) {
        if (ConfJson.debug) {
            console.log(event)
        }
    };

    state.websocket.onopen = function (event) {
        if (ConfJson.debug) {
            console.log(event)
        }
        sendEventToWebsocket(state, "auth", "")
        if (callback !== undefined && callback !== null)
            callback();
    };
    state.websocket.onmessage = function (event) {
        /*        if (state.rtc_connection.status_color === "danger") {
                    alert(event.data)
                }*/
        let myEvent = JSON.parse(event.data)
        let answer;
        //console.log(myEvent)
        switch (myEvent.command) {
            case "set_b64_read":

                answer = JSON.parse(atob(myEvent.value))
                answer.sdp = setVideoBitrates(answer.sdp, {
                    min: 500,
                    //max: 3000000
                    max: 5000000
                });

                /*.sdp = removeBandwidthRestriction(answer.sdp)*/
                state.rtc_connection.pcRead.setRemoteDescription(new RTCSessionDescription(answer));
                //state.sdp.SdpManager= state.rtc_connection.pcRead.remoteDescription.sdp
                break;
            case "set_b64_write":
                state.rtc_connection.pcWrite.setRemoteDescription(new RTCSessionDescription(JSON.parse(atob(myEvent.value))));
                break;
            case "set_ice_read":
                state.rtc_connection.pcRead.addIceCandidate(JSON.parse(myEvent.value)).catch(e => {
                    if (ConfJson.debug) {
                        console.log("Failure during addIceCandidate(): " + e.name);
                    }
                });
                break;
            case "set_ice_write":
                state.rtc_connection.pcWrite.addIceCandidate(JSON.parse(myEvent.value)).catch(e => {
                    if (ConfJson.debug) {
                        console.log("Failure during addIceCandidate(): " + e.name);
                    }
                });
                break;
            case "upd_key":
                switch (myEvent.value) {
                    case "RELOAD":
                        closeConnectionRead(state);
                        newConnectionRead(state);
                        break
                    case "RESET":
                        closeConnectionRead(state);
                        location.reload();
                        break
                    case "ON_CALL":
                        break
                    case "NEW_INCOMMING_SRC":
                        newConnectionWrite(state);
                        break
                    default:
                        break
                }
                break;
            case "resolution":
                for (let i = 0; i < state.rtc_connection.list_of_resolution.length; i++) {
                    if (state.rtc_connection.list_of_resolution[i].resolution === myEvent.value) {
                        state.rtc_connection.currentResolution = state.rtc_connection.list_of_resolution[i]
                        break
                    }
                }
                changeResolutionAndFramerate(state, true)
                //SET THE NEW RESOLUTION REQUIRED (REQUESTED)  FROM MANAGER
                break;
            case "framerate":
                state.rtc_connection.framerate = parseInt(myEvent.value)
                changeResolutionAndFramerate(state, false)
                //SET THE NEW FRAMERATE REQUIRED (REQUESTED) FROM MANAGER
                break;
            case "camera":
                activeCamera(state)
                //SET THE CAMERA ON / OFF
                break;
            default:

                break;
        }
    };
    state.websocket.onclose = function (event) {
        if (ConfJson.debug) {
            alert("WEBSOCKET FERMÉ ! ");
            /*alert(JSON.stringify(event));*/
        }
        if (ConfJson.debug) {
            console.log(event)
        }
    }
}

function sendEventToWebsocket(state, command, value) {
    let s = localStorage.getItem("s")
    let objEvt = {"s": s, "command": command, "value": value}
    state.websocket.send(JSON.stringify(objEvt))
}

/*
* TOOLS SORT ARRAY
* */

function compare(a, b) {
    if (a.min_bandwidth > b.min_bandwidth) {
        return -1;
    }
    if (a.min_bandwidth < b.min_bandwidth) {
        return 1;
    }
    return 0;
}

